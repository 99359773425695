import React from "react";
import Bluebird from "bluebird";
import qs from "querystring";
import { connect } from "react-redux";
import { autobind } from "react-decoration";
import Link from "next/link";
import Router, { withRouter } from "next/router";
import Immutable from "immutable";
import { pathToRegexp } from "path-to-regexp";
import env from "@beam-australia/react-env";
import Modal from "@/ReUtil/components/Modal";
import ReactImages from "@/ReUtil/components/ReactImages";
import Sidebar from "@/ReUtil/components/ReactSidebar";
import Forbidden from "../../../../pages/403";
import pages from "@/constants/pages";
import { action } from "@/modules";
import * as api from "@/api";
import css from "./Main.module.scss";

const ignorePaths = ["/demo"];

@withRouter
@connect(
	(state) => ({
		user: state.base.user,
		version: state.base.version
	}),
	(dispatch) => ({
		setUser: (user, access_token) =>
			dispatch(action.base.setUser(user, access_token)),
		setAppVersion: (version) =>
			dispatch(action.base.setAppVersion(version))
	})
)
class Main extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			sidebar: false,
		};
	}

	@autobind
	logout() {
		this.props.setUser();
		localStorage.removeItem(process.env.NEXT_PUBLIC_ACCESS_TOKEN_KEY);
		return Router.replace("/");
	}

	@autobind
	async redirectIfLoggedIn() {
		const pathname = this.props.router.pathname;

		if (pathname == "/") {
			await Router.push("/dashboard");
		}

		return this.setState({ loaded: true });
	}

	componentDidUpdate(prevProps) {
		if (
			this.socket &&
			prevProps.router.pathname != this.props.router.pathname
		) {
			this.socket.emit({
				type: "path",
				payload: this.props.router.pathname,
			});
		}
	}

	async componentDidMount() {
		const pathname = this.props.router.pathname;

		/**
		 *
		 * Ignore the default path name
		 *
		 */
		if (ignorePaths.includes(pathname)) return;

		/**
		 *
		 * If user already been setted
		 *
		 */
		if (this.props.user) {
			return this.redirectIfLoggedIn();
		}

		/**
		 *
		 * Check if access token is stored in local storage
		 * If Exist, try to login
		 *
		 */
		let access_token = localStorage.getItem(
			process.env.NEXT_PUBLIC_ACCESS_TOKEN_KEY
		);
		const queries = qs.parse(this.props.router.asPath.replace(/^\/\#/, ""));

		if (!access_token) access_token = queries.access_token;

		if (access_token) {
			try {
				const { auth, version } = await Bluebird.props({
					auth: api.user.me(access_token),
					version: api.system.getAppVersion(),
				});
				this.props.setUser(auth.user, auth.access_token);
				this.props.setAppVersion(version);
				localStorage.setItem(
					process.env.NEXT_PUBLIC_ACCESS_TOKEN_KEY,
					auth.access_token
				);
				return this.redirectIfLoggedIn();
			} catch (error) {
				console.error(error);
				window.alert(error?.response?.data?.message ?? error.message);
				/**
				 *
				 * Remove the local storage access token
				 *
				 */
				localStorage.removeItem(
					process.env.NEXT_PUBLIC_ACCESS_TOKEN_KEY
				);
			}
		}

		/**
		 *
		 * All else failed
		 *
		 */
		localStorage.removeItem(process.env.NEXT_PUBLIC_ACCESS_TOKEN_KEY);

		if (pathname != "/") await Router.replace("/");

		return this.setState({ loaded: true });
	}

	@autobind
	isCurrentDirectory(page) {
		const pathname = this.props.router.pathname;
		return page.page && pathToRegexp(page.page).exec(pathname);
	}

	@autobind
	getCurrentPage() {
		const _pages = pages.sidebar;
		const pathname = this.props.router.pathname;

		if (pathname == "/500") {
			return {
				name: "Internal Server Error",
			};
		}

		if (pathname == "/404") {
			return {
				name: "Not Found",
			};
		}

		for (const page of _pages.filter((item) => typeof item !== "string")) {
			if (page.page && pathToRegexp(page.page).exec(pathname))
				return page;
			if (page.menu) {
				for (const item of page.menu) {
					if (typeof item !== "string")
						if (pathToRegexp(item.page).exec(pathname)) return item;
				}
			}
			if (page.sub_pages) {
				for (const item of page.sub_pages) {
					if (pathToRegexp(item.page).exec(pathname)) return item;
				}
			}
		}
	}

	@autobind
	getSidebar() {
		const user = this.props.user;
		const polices = user?.permissions || [];

		let sidebar = Immutable.List(pages.sidebar).toJS();

		sidebar = sidebar.map((page) => {
			if (page.type == "dropdown") {
				page.menu = page.menu.filter((page) => {
					if (page.permissions && page.permissions.length > 0) {
						return page.permissions.some((permission) =>
							polices.includes(permission)
						);
					}
					return true;
				});
			}
			return page;
		});

		sidebar = sidebar.filter((page) => {
			if (page.type == "dropdown") {
				return page.menu.length > 0;
			} else if (page.permissions && page.permissions.length > 0) {
				return page.permissions.some((permission) =>
					polices.includes(permission)
				);
			}
			return true;
		});

		sidebar = sidebar
			.map((page) => {
				if (page.type == "dropdown") {
					page.menu = clearTabs(page.menu);
				}
				return page;
			})
			.filter((page) => {
				if (page.type == "dropdown") {
					return page.menu.length > 0;
				} else if (page.permissions && page.permissions.length > 0) {
					return page.permissions.some((permission) =>
						polices.includes(permission)
					);
				}
				return true;
			});

		return sidebar;
	}

	@autobind
	renderSidebar() {
		return (
			<>
				<ul className={css["sidebar-list-narrow"]} >
					{this.getSidebar().map((item, index) => {
						if (typeof item == "string")
							return <React.Fragment key={index} />;
						if (item.type == "dropdown") {
							return (
								<li
									key={index}
									className={css["dropdown-narrow"]}
								>
									<a>
										{typeof item.icon === "string" ||
										item.type == "icon" ? (
											<i className={item.icon} />
										) : (
											<img
												src={item.icon.url}
												className={css["sidebar-icon"]}
											/>
										)}
									</a>
									<ul className={css["sidebar-list"]} style={{width:'280px'}}>
										{(item.menu ?? []).map(
											(item, index) => {
												if (typeof item == "string") {
													return (
														<span
															key={index}
															className={
																css[
																	"menu-title"
																]
															}
														>
															{item}
														</span>
													);
												}

												return (
													<li
														key={index}
														className={
															this.isCurrentDirectory(
																item
															)
																? css[
																		"active-item"
																  ]
																: void 0
														}
													>
														<Link href={item.page}>
															<a>
																<i
																	className={
																		item.icon
																	}
																/>
																<span>
																	{item.name}
																</span>
															</a>
														</Link>
													</li>
												);
											}
										)}
									</ul>
								</li>
							);
						}
						return (
							<li
								key={index}
								className={
									this.isCurrentDirectory(item)
										? css["active-item"]
										: void 0
								}
							>
								<Link href={item.page}>
									<a>
										<i className={item.icon} />
									</a>
								</Link>
							</li>
						);
					})}
				</ul>
				<ul className={css["sidebar-list-narrow"]}>
					<li onClick={this.logout}>
						<a>
							<i className="fa fa-sign-out" />
						</a>
					</li>
				</ul>
			</>
		);
	}

	@autobind
	renderFullSidebar() {
		return (
			<>
				<ul className={css["sidebar-list"]} >
					{this.getSidebar().map((item, index) => {
						if (typeof item == "string")
							return (
								<li key={index} className={css.catalog}>
									<span>{item}</span>
								</li>
							);

						if (item.type == "dropdown") {
							return (
								<li key={index} className={css.dropdown}>
									<a>
										<span>
											<i className={item.icon} />
											<span>{item.name}</span>
										</span>
										<i className="fa fa-angle-right" />
									</a>
									<ul className={css["sidebar-list"]} style={{width:'280px'}}>
										{(item.menu ?? []).map(
											(item, index) => (
												<li
													key={index}
													className={
														this.isCurrentDirectory(
															item
														)
															? css["active-item"]
															: void 0
													}
												>
													{typeof item == "string" ? (
														<span
															key={index}
															className={
																css[
																	"menu-title"
																]
															}
														>
															{item}
														</span>
													) : (
														<Link href={item.page}>
															<a>
																<i
																	className={
																		item.icon
																	}
																/>
																<span>
																	{item.name}
																</span>
															</a>
														</Link>
													)}
												</li>
											)
										)}
									</ul>
								</li>
							);
						}

						return (
							<li
								key={index}
								className={
									this.isCurrentDirectory(item)
										? css["active-item"]
										: void 0
								}
							>
								<Link href={item.page}>
									<a>
										<i className={item.icon} />
										<span>{item.name}</span>
									</a>
								</Link>
							</li>
						);
					})}
				</ul>
				<ul className={css["sidebar-list"]}>
					<li onClick={this.logout}>
						<a>
							<i className="fa fa-sign-out" />
							<span>Sign out</span>
						</a>
					</li>
				</ul>
			</>
		);
	}

	@autobind
	renderContent() {
		const { sidebar } = this.state;
		const { pathname } = this.props.router;
		const appEnv = env("APP_ENV") || "development";

		if (["/", "/login", "/demo"].includes(pathname)) {
			return this.props.children;
		}

		const { user, version } = this.props;
		const polices = user?.permissions || [];

		const currentPage = this.getCurrentPage();
		const isPermitted = !currentPage?.permissions
			? true
			: currentPage.permissions.some((permission) =>
					polices.includes(permission)
			  );
		const isProd = appEnv === "production";

		return (
			<>
				<nav className={css.navbar}>
					<div
						className={
							css[
								sidebar
									? "navbar-header"
									: "navbar-header-narrow"
							]
						}
					/>
					<div className={css["navbar-main"]}>
						<i
							className="fa fa-align-left pointer"
							onClick={() => this.setState({ sidebar: !sidebar })}
						/>
						<h3>{currentPage?.name}</h3>
					</div>
					<div className={css["navbar-side"]}>
						{!isProd && (
							<div className={css["navbar-env"]}>
								{appEnv} Environment
							</div>
						)}

						<div className={css["navbar-version"]}>
							<div className="info">
								<span>Infinite Commerce LMS</span>
								{version && (
									<span
										className="meta"
										style={{ fontSize: 12, marginLeft: 4 }}
									>
										({version.appVersion})
									</span>
								)}
							</div>
						</div>

						<div className={css["navbar-user"]}>
							<i className="fa fa-user" />
							<div>
								<label>{user?.name}</label>
								<span className="meta">{user?.email}</span>
							</div>
						</div>
					</div>
				</nav>
				<div
					className={
						css[
							sidebar ? "page-container" : "page-container-narrow"
						] + " error-page"
					}
				>
					<aside
						className={
							sidebar ? css.sidebar : css["sidebar-narrow"]
						}
					>
						{sidebar ? (
							<this.renderFullSidebar />
						) : (
							<this.renderSidebar />
						)}
					</aside>
					<div className={css["page-content"]}>
						{isPermitted ? this.props.children : <Forbidden />}
					</div>
				</div>
			</>
		);
	}

	render() {
		const { loaded } = this.state;
		const pathname = this.props.router.pathname;

		if (ignorePaths.includes(pathname)) {
			return <this.renderContent />;
		}

		if (loaded) {
			return (
				<>
					<Sidebar>
						<this.renderContent />
					</Sidebar>
					<Modal />
					<ReactImages />
				</>
			);
		}

		return (
			<div className={css.loading}>
				<img src="/loading.svg" />
			</div>
		);
	}
}

function clearTabs(array) {
	let items = Immutable.List(array).toJS();

	let lastTabIndex = null;

	for (let index = 0; index < items.length; index++) {
		if (typeof items[index] === "string") {
			if (lastTabIndex != null) {
				items[lastTabIndex] = null;
			}
			lastTabIndex = index;
		} else {
			lastTabIndex = null;
		}
	}

	items = items.filter((item) => item);

	if (typeof items[items.length - 1] === "string") {
		items.pop();
	}

	return items;
}

export default Main;
